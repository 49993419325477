import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import edjsHTML from "editorjs-html";

export default function About() {
  const edjsParser = edjsHTML();
  const dictionary = useSelector((state) => state.dictionary.dictionary);
  const about = dictionary.find(
    (element) => element.id == "88f414ec-cc4b-11ee-9cae-0242ac120003"
  );
  let html = [];
  if (about) {
    html = edjsParser.parse(JSON.parse(about.text));
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", py: 4 }}
    >
      <Helmet>
        <title>{"Nasz zespół - Centrum Konsumenckie"}</title>
        <meta
          name="description"
          content="Portal centrumkonsumenckie.pl powstał po to, by pomagać nabywcom dóbr i usług. Działając w systemie
          ochrony konsumentów od kilkunastu lat wiemy jakie są jego słabe i
          mocne strony."
        />
      </Helmet>
      <Grid item xs={11} md={7} pt={1} sx={{ mt: 2 }}>
        <Typography
          variant="h5"
          color="text.primary"
          paragraph={true}
          sx={{ textAlign: "center", fontWeight: 700 }}
        >
          Nasz zespół
        </Typography>
        <Typography variant="body1" color="text.primary">
          {html.map((item) => parse(item))}
        </Typography>
      </Grid>
    </Grid>
  );
}
