import * as React from "react";
import Grid from "@mui/material/Grid";
import AnswerForm from "../features/answer/AnswerForm";

export default function AnswerPage() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: "primary.main", py: 14, minHeight: "80dvh" }}
    >
      <AnswerForm/>
    </Grid>
  );
}
