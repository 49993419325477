import React from "react";
import Typography from "@mui/material/Typography";
import { Grid, Button } from "@mui/material";

export default function AnswerAction(props) {
  let help_button = (
    <Grid item xs={3} sm={2} md={2} xl={2} p={1} display="flex" justifyContent="end">
      <Button
        onClick={(event) => {
          event.preventDefault();
          if (props.action) props.action();
        }}
        sx={{
          color: props.buttonVariant === "contained" ? "text.secondary" : "",
        }}
        color={"dark"}
        variant={props.buttonVariant}
      >
        Wybierz
      </Button>
    </Grid>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        minHeight: 80,
        p: 2,
        mb: 2,
        border: "1px solid",
        borderColor: "dark",
      }}
    >
      <Grid item xs={12} sm={9} md={9} xl={9} p={1} >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {props.label || ""}
        </Typography>
      </Grid>
      {help_button}
    </Grid>
  );
}
