import * as React from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import EditorRead from "../components/editor/EditorRead";

export default function Privacy() {
  const dictionary = useSelector((state) => state.dictionary.dictionary);
  const policy = dictionary.find(
    (element) => element.id == "b5437632-cdda-11ee-9cae-0242ac120003"
  );

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", py: 4 }}
    >
      <Helmet>
        <title>{"Polityka prywatności - Centrum Konsumenckie"}</title>
        <meta
          name="description"
          content="Polityka prywatności - Centrum Konsumenckie"
        />
      </Helmet>
      <Grid item xs={11} md={7} pt={1} sx={{ mt: 2 }}>
      {policy && policy.text[0] == "{" ? (
          <EditorRead
            data={JSON.parse(policy.text)}
            editorblock="editorjsRead-container"
          />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
