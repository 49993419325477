import * as React from "react";
import { Box, Typography } from "@mui/material";
import AnswerAction from "./AnswerAction";
import { useNavigate } from "react-router-dom";

export default function AnswerActionList(props) {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 6 }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "2rem", mb: 6, fontWeight: 700 }}
      >
        Co możesz zrobić:
      </Typography>
      {props.formID ? (
        <AnswerAction
          label={"Chcę zareklamować towar"}
          action={() => navigate("/reklamacja/" + props.formID + "?porada=" + props.answerID)}
          buttonVariant={"outlined"}
        />
      ) : null}
      <AnswerAction
        label={"Zapisz poradę do PDF"}
        action={props.downloadMethod}
        buttonVariant={"outlined"}
      />
      <AnswerAction
        label={"Wydrukuj poradę"}
        action={props.printMethod}
        buttonVariant={"outlined"}
      />
      {/* <AnswerAction label={"Skorzystaj z encyklopedii konsumenckiej"} buttonVariant={"outlined"} /> */}
      {/* <AnswerAction label={"Zasugeruj pytanie"} buttonVariant={"outlined"} /> */}
      <AnswerAction
        label={"Skontaktuj się z nami"}
        action={props.goToContact}
        buttonVariant={"outlined"}
      />
    </Box>
  );
}
