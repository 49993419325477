import React from "react";
import { Grid, Button, Box, CircularProgress } from "@mui/material";
import OptionList from "./OptionList";
import QuestionText from "./QuestionText";
import { useNavigate, useParams } from "react-router-dom";
import { useGetQuestionQuery } from "../api/defaultApi";
import QuestionProgress from "./QuestionProgress";
import { Helmet } from "react-helmet";
import Error from "../../components/Error";

export default function QuestionForm() {
  let navigate = useNavigate();
  const { id } = useParams();
  const { data: question, isLoading, isError, error } = useGetQuestionQuery(id);

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (isError || !question) {
    console.error(error);
    return <Error error={error} />;
  }

  const handleNextQuestion = (optionId) => {
    let optionObject = question.question_options.find(
      (option) => option.id === optionId
    );
    if (optionObject.next_question_id) {
      navigate("/pytanie/" + optionObject.next_question_id);
    } else if (optionObject.answer_id) {
      navigate("/porada/" + optionObject.answer_id);
    }
  };

  if (question)
    return (
      <Grid item xs={11} md={7} pt={1} sx={{ mt: 2 }}>
        <Helmet>
          <title>
            {id
              ? "Formularz - " + question.label + " | Centrum Konsumenckie"
              : "Centrum Konsumenckie"}
          </title>
          <meta
            name="description"
            content={
              "Formularz porady konsumenckiej - " +
              question.label +
              ": " +
              question.question_options
            }
          />
        </Helmet>
        <QuestionProgress
          display={question.order_number > 1 ? "" : "none"}
          value={
            (question.order_number / 2) * 10 < 100
              ? (question.order_number / 2) * 10
              : 95
          }
        />
        <div id="QuestionText"></div>
        <QuestionText title={question.label} text={question.help_text} />
        <OptionList
          options={question.question_options}
          onSelect={handleNextQuestion}
        />
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Button
            key={"backButton"}
            onClick={() => {
              navigate(-1);
            }}
            color={"dark"}
            variant="text"
          >
            {"<-"} Wróć na poprzednią stronę
          </Button>
        </Box>
      </Grid>
    );
}
