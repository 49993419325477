import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Grid, Button, Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetFormQuery, useGetAnswerQuery } from "../api/defaultApi";
import AdvertiseFormBody from "./AdvertiseFormBody";
import AnswerAction from "../answer/AnswerAction";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import Error from "../../components/Error";

export default function AdvertiserForm() {
  // const [open, setOpen] = React.useState(false);
  // const [showAnswer, setShowAnswer] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const componentRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let answerID = searchParams.get("porada");

  const {
    data: advertiseForm,
    isLoading,
    isError,
    error,
  } = useGetFormQuery(id);
  const {
    data: answer,
    isLoading2,
    isError2,
    error2,
  } = useGetAnswerQuery(answerID);

  const formInitialLoad = () => {
    if (answer) {
      var variable1 = document.getElementById("1");
      if (answer.form_variable1 && variable1) {
        variable1.value = answer.form_variable1;
      }
      var variable2 = document.getElementById("2");
      if (answer.form_variable2 && variable2) {
        variable2.value = answer.form_variable2;
      }
      var variable3 = document.getElementById("3");
      if (answer.form_variable3 && variable3) {
        variable3.value = answer.form_variable3;
      }
    }
  };

  const validateBeforePrint = () => {
    var form = document.getElementById("advertiserForm");
    if (form && form.reportValidity()) {
      var textAreas = document.querySelectorAll("textarea");
      textAreas.forEach(function (textarea) {
        textarea.style.height = 35 + textarea.scrollHeight + "px";
        textarea.style.width = "100%";
      });
      handlePrint();
    }
  };

  const sentByEmail = () => {
    var form = document.getElementById("advertiserForm");
    if (form && form.reportValidity()) {
      var inputs = document.querySelectorAll("input");
      inputs.forEach(function (input) {
        const el = document.createElement("label");
        el.classList.add("toBeRemoved");
        el.innerText = ": " + input.value;
        input.parentNode.appendChild(el);
      });

      var textAreas = document.querySelectorAll("textarea");
      textAreas.forEach(function (textarea) {
        const el = document.createElement("label");
        el.classList.add("toBeRemoved");
        el.innerText = "" + textarea.value;
        textarea.parentNode.appendChild(el);
      });

      var emailInputs = document.querySelectorAll('input[type="email"]');
      var emailValues = [];

      // Loop through each email input and retrieve its value
      emailInputs.forEach(function (input) {
        emailValues.push(input.value);
      });
      const recipient = emailValues.toString();
      const subject = advertiseForm.label;
      var strippedHtml = componentRef.current.innerText;
      strippedHtml = strippedHtml.replace(/<style([\s\S]*?)<\/style>/gi, "");
      strippedHtml = strippedHtml.replace(/<script([\s\S]*?)<\/script>/gi, "");
      strippedHtml = strippedHtml.replace(/<\/div>/gi, "\n");
      strippedHtml = strippedHtml.replace(/<\/li>/gi, "\n");
      strippedHtml = strippedHtml.replace(/<li>/gi, "  *  ");
      strippedHtml = strippedHtml.replace(/<\/ul>/gi, "\n");
      strippedHtml = strippedHtml.replace(/<\/p>/gi, "\n");
      strippedHtml = strippedHtml.replace(/<br\s*[\/]?>/gi, "\n");
      strippedHtml = strippedHtml.replace(/<[^>]+>/gi, "");
      const body = strippedHtml;
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;

      var elements = document.getElementsByClassName("toBeRemoved");

      // Convert HTMLCollection to array to safely iterate over it
      var elementsArray = Array.from(elements);

      // Iterate over the array and remove each element
      elementsArray.forEach(function (element) {
        element.remove();
      });
    }
  };

  // handle print action, also with GA4
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: advertiseForm?.title,
    pageStyle:
      "@page { size: A4;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    onBeforePrint: () => {
      ReactGA.event({
        category: "Druk Formularzu Reklamacyjnego",
        action: "print_form",
        label: "Wydrukuj formularz reklamacyjny", // optional
      });
    },
  });

  // for the next version
  // useEffect(() => {
  //   if (advertiseForm && advertiseForm.show_support) {
  //     const timer = setTimeout(() => {
  //       handleOpen();
  //     }, 20000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [advertiseForm]);
  // useEffect(() => {
  //   if (advertiseForm) {
  //     setShowAnswer(!advertiseForm.show_assessment);
  //   }
  //   if (advertiseForm && advertiseForm.show_support) {
  //     const onBeforeUnload = (e) => {
  //       handleOpen();
  //       e.preventDefault();
  //       e.returnValue = "";
  //     };
  //     window.addEventListener("beforeunload", onBeforeUnload);
  //     return () => {
  //       window.removeEventListener("beforeunload", onBeforeUnload);
  //     };
  //   }
  // }, [advertiseForm]);

  if (isLoading || isLoading2) {
    return <CircularProgress color="secondary" />;
  }

  if (isError || !advertiseForm) {
    return (
      <Grid sx={{ textAlign: "center" }} item xs={10} sm={6} md={6} xl={2}>
        <Error error={error} />
      </Grid>
    );
  }

  // if (!showAnswer && advertiseForm) {
  //   return (
  //     <Grid item xs={11} md={6} pt={1}>
  //       <Typography
  //         variant="h3"
  //         color="secondary"
  //         sx={{ mb: 4, fontWeight: 700, fontSize: "2.8rem" }}
  //       >
  //         Porada konsumenckia
  //       </Typography>
  //       <Typography
  //         variant="body2"
  //         color="dark"
  //         sx={{ mb: 4, fontWeight: 400 }}
  //       >
  //         W następnym kroku będziesz mógł(a) zapoznać się z poradą. Zanim jednak
  //         do tego przejdziemy prosimy o wypełnienie krótkiej ankiety, która
  //         pomoże nam lepiej wspierać konsumentki i konsumentów w ich problemach.
  //         Jeśli się spieszysz i pominiesz ten krok, to zrozumiemy, choć nasza
  //         wiedza o rynku będzie przez to uboższa. Pamiętaj, Twój głos jest
  //         ważny! Czy ankietę wypełnisz, czy też nie, dziękujemy za skorzystanie
  //         z naszych usług!
  //       </Typography>
  //       <Button
  //         key={"backButton"}
  //         color={"dark"}
  //         variant="outlined"
  //         onClick={() => setShowAnswer(!showAnswer)}
  //       >
  //         Przejdź do porady
  //       </Button>
  //     </Grid>
  //   );
  // }

  if (advertiseForm && answer) {
    return (
      <Grid item xs={11} md={7}>
        <Helmet>
          <title>
            {"Reklamator - " + advertiseForm.label + " | Centrum Konsumenckie"}
          </title>
          <meta
            name="description"
            content={"Reklamator - " + advertiseForm.label}
          />
        </Helmet>
        <form id="advertiserForm">
          <AdvertiseFormBody
            refForBody={componentRef}
            form={advertiseForm}
            formInitialLoad={formInitialLoad}
          />
          <AnswerAction
            label={"Wygeneruj reklamację"}
            action={validateBeforePrint}
            buttonVariant={"outlined"}
          />
          <AnswerAction
            label={"Prześlij reklamację do przedsiębiorcy mailem"}
            action={sentByEmail}
            buttonVariant={"outlined"}
          />
        </form>
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Button
            key={"backButton"}
            onClick={() => navigate(-1)}
            color={"dark"}
            variant="outlined"
            sx={{
              minWidth: 230,
              mb: 0,
              height: 40,
            }}
          >
            {"<-"} Wróć na poprzednią stronę
          </Button>
        </Box>

        {/* <Dialog
          open={open}
          onClose={handleClose}
          sx={{ bgcolor: "#FDF3E6" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "Cieszymy się, że skorzystałeś z naszego portalu i trzymamy kciuki, żeby porada okazała się pomocna!"
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="black" id="alert-dialog-description">
              Do powstania tej strony przyczyniła się wiedza wielu organizacji
              konsumenckich, więc jeśli zdecydujesz się wesprzeć finansowo jedną
              z nich (albo i wszystkie!) pozwoli im to jeszcze skuteczniej
              pomagać konsumentom.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              key={"backButton"}
              color={"dark"}
              variant="outlined"
              onClick={handleClose}
            >
              Przejdź do porady
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  }
}
