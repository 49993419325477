import React from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import EditorComponent from "../../components/editor/EditorComponent";
import { useSaveRecordMutation } from "../api/defaultApi";
import { useNavigate } from "react-router-dom";
import Error from "../../components/Error";
import { FormInputSelect } from "./FormInputSelect";

const INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      type: "paragraph",
      data: {
        text: "Wpisz tekst",
        level: 1,
      },
    },
  ],
};

const SCHEMA = {
  question: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "name",
      label: "Nazwa",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "label",
      label: "Etykieta",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "category",
      label: "Kategoria",
      type: "choice",
      choices: [
        { label: "-", value: "" },
        { label: "Usługi", value: "uslugi" },
        { label: "Towary", value: "towary" },
      ],
      defaultValue: "",
      disabled: false,
      required: false,
      multiline: false,
    },
    {
      name: "help_text",
      label: "Tekst Podpowowiedzi",
      type: "html",
      defaultValue: INITIAL_DATA,
      disabled: false,
      required: false,
      multiline: false,
    },
    {
      name: "order_number",
      label: "Kolejność",
      type: "number",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
  ],
  question_option: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "active",
      label: "Aktywny",
      type: "boolean",
      defaultValue: true,
      disabled: false,
      multiline: false,
    },
    {
      name: "question_id",
      label: "ID Pytania",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "label",
      label: "Etykieta",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "help_text",
      label: "Tekst Podpowowiedzi",
      type: "html",
      defaultValue: INITIAL_DATA,
      disabled: false,
      required: false,
      multiline: false,
    },
    {
      name: "order_number",
      label: "Kolejność",
      type: "number",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "next_question_id",
      label: "Odpowiedz - ID Pytania",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: false,
      multiline: false,
    },
    {
      name: "answer_id",
      label: "Odpowiedz - ID Porady",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: false,
      multiline: false,
    },
  ],
  answer: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "name",
      label: "Nazwa",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "label",
      label: "Etykieta",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "category",
      label: "Kategoria",
      type: "choice",
      choices: [
        { label: "-", value: "" },
        { label: "Usługi", value: "uslugi" },
        { label: "Towary", value: "towary" },
      ],
    },
    {
      name: "text",
      label: "Treść Porady",
      type: "html",
      defaultValue: INITIAL_DATA,
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "form_id",
      label: "ID Formularza Reklamacyjnego",
      type: "string",
      disabled: false,
    },
    {
      name: "form_variable1",
      label: "Zmienna numer 1",
      type: "string",
      disabled: false,
    },
    {
      name: "form_variable2",
      label: "Zmienna numer 2",
      type: "string",
      disabled: false,
    },
    {
      name: "form_variable3",
      label: "Zmienna numer 3",
      type: "string",
      disabled: false,
    },

  ],
  module: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "name",
      label: "Nazwa",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "active",
      label: "Aktywny",
      type: "boolean",
      defaultValue: true,
      disabled: false,
      required: true,
      multiline: false,
    },
  ],
  sys_dictionary: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "name",
      label: "Nazwa",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: true,
      multiline: false,
    },
    {
      name: "text",
      label: "Treść",
      type: "html",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: true,
    },
  ],
  user: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "login",
      label: "Login",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "password",
      label: "Hasło",
      type: "password",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "role",
      label: "Rola",
      type: "choice",
      choices: [{ label: "Admin", value: "admin" }],
      defaultValue: "admin",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "active",
      label: "Aktywny",
      type: "boolean",
      defaultValue: true,
      disabled: false,
      multiline: false,
    },
  ],
  form: [
    {
      name: "id",
      label: "ID",
      type: "string",
      defaultValue: "",
      disabled: true,
      required: false,
      multiline: false,
    },
    {
      name: "name",
      label: "Nazwa",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "label",
      label: "Etykieta",
      type: "string",
      defaultValue: "",
      disabled: false,
      required: true,
      multiline: false,
    },
    {
      name: "text",
      label: "Treść Porady",
      type: "html",
      defaultValue: INITIAL_DATA,
      disabled: false,
      required: true,
      multiline: false,
    },
  ],
};

const TABLE_LABELS = {
  question: "Pytania",
  question_option: "Odpowiedzi na pytania",
  answer: "Porady",
  module: "Moduł",
  sys_dictionary: "Słownik systemowy",
  module: "Użytkownicy",
  form: "Formularz Reklamacyjny",
};

export default function Form({ tableName, id, record }) {
  const recordSchema = SCHEMA[tableName];
  let navigate = useNavigate();
  const [data, setData] = React.useState(INITIAL_DATA);
  const [open, setOpen] = React.useState(false);
  const [editorKey, setEditorKey] = React.useState(0); // fix for not updateing bug

  const [saveRecord, { isLoading, isError, error, isSuccess }] =
    useSaveRecordMutation();

  const { control, register, handleSubmit, reset, setValue, formState, watch } =
    useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: recordSchema.map((item) => item.defaultValue),
    });

  const { errors } = formState;

  React.useEffect(() => {
    reset();
    if (record) {
      recordSchema.forEach((field) => setValue(field.name, record[field.name]));
      if (
        (tableName == "answer" || tableName == "sys_dictionary" || tableName == "form") &&
        record.text &&
        record.text[0] == "{"
      ) {
        setData(JSON.parse(record.text));
      } else if (
        (tableName == "question" || tableName == "question_option") &&
        record.help_text &&
        record.help_text[0] == "{"
      ) {
        setData(JSON.parse(record.help_text));
      }
    }
    setEditorKey(prevKey => prevKey + 1);
  }, [record]);

  React.useEffect(() => {
    if (isSuccess) {
      setOpen(true);
    }
  }, [isLoading]);

  const onSubmitHandler = (values) => {
    values[
      tableName == "answer" || tableName == "sys_dictionary" || tableName == "form"
        ? "text"
        : "help_text"
    ] = JSON.stringify(data);
    saveRecord({ tableName, id, data: values }).then((response) => {
      if (!response.error) {
        if (id == "-1") {
          navigate(
            "/admin/form?table=" + tableName + "&id=" + response.data.id,
            { replace: true }
          );
        }
        setData(data);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  if (isError) {
    return <Error error={error} />;
  }

  if (isLoading) {
    return <CircularProgress sx={{ my: 30 }} color="secondary" />;
  }

  return (
    <Grid item xs={11} md={8} xl={5} my={6}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Zapisano!
        </Alert>
      </Snackbar>
      <Typography
        variant="h3"
        color="secondary"
        sx={{ mb: 3, fontWeight: 700, fontSize: "1.5rem" }}
      >
        Formularz {TABLE_LABELS[tableName]}
      </Typography>
      <FormProvider {...control}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
        >
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            {recordSchema.map((item) => {
              if (item.type == "string") {
                return (
                  <TextField
                    key={item.name}
                    disabled={item.disabled}
                    name={item.name}
                    label={item.label}
                    type="text"
                    length="255"
                    color="secondary"
                    variant="standard"
                    fullWidth
                    multiline={item.multiline}
                    error={!!errors[item.name]}
                    helperText={errors[item.name]?.message}
                    required={item.required}
                    sx={{
                      mb: 4,
                    }}
                    {...register(item.name, { required: item.required })}
                    InputLabelProps={{ shrink: !!watch(item.name) }}
                  />
                );
              } else if (item.type == "number") {
                return (
                  <TextField
                    key={item.name}
                    name={item.name}
                    label={item.label}
                    type="number"
                    required={item.required}
                    color="secondary"
                    variant="standard"
                    fullWidth
                    error={!!errors[item.name]}
                    sx={{
                      mb: 4,
                    }}
                    {...register(item.name, { required: item.required })}
                    InputLabelProps={{ shrink: !!watch(item.name) }}
                  />
                );
              } else if (item.type == "choice") {
                return (
                  <FormInputSelect
                    name={item.name}
                    control={control}
                    label={item.label}
                    choices={item.choices}
                  />
                );
              } else if (item.type == "boolean") {
                return (
                  <Box sx={{ width: "100%", mb: 4 }} key={item.name}>
                    <FormControlLabel
                      key={item.name}
                      {...register(item.name)}
                      control={
                        <Checkbox
                          color="secondary"
                          checked={!!watch(item.name)}
                          sx={{
                            color: "text.dark",
                            "& .MuiSvgIcon-root": { fontSize: 30 },
                          }}
                        />
                      }
                      label={item.label}
                    />
                  </Box>
                );
              } else if (item.type == "password") {
                return (
                  <>
                    <TextField
                      key={item.name}
                      name={item.name}
                      label={item.label}
                      type="password"
                      required={item.required && id == "-1" ? true : false}
                      color="secondary"
                      variant="standard"
                      fullWidth
                      helperText={errors[item.name]?.message || ""}
                      error={!!errors[item.name]}
                      sx={{
                        mb: 4,
                      }}
                      {...register(item.name, {
                        required: item.required && id == "-1" ? true : false,
                        minLength: {
                          value: 8,
                          message: "Minimum 8 znaków",
                        },
                      })}
                      InputLabelProps={{ shrink: !!watch(item.name) }}
                    />
                    <TextField
                      key={item.name + "2"}
                      name={item.name + "2"}
                      label={"Powtórz " + item.label}
                      type="password"
                      required={item.required && id == "-1" ? true : false}
                      color="secondary"
                      variant="standard"
                      fullWidth
                      helperText={errors[item.name + "2"]?.message || ""}
                      error={!!errors[item.name + "2"]}
                      sx={{
                        mb: 4,
                      }}
                      {...register(item.name + "2", {
                        validate: (value) => {
                          if (
                            (item.required && id == "-1") ||
                            watch(item.name)
                          ) {
                            return (
                              value === watch(item.name) ||
                              "Hasło się nie zgadza"
                            );
                          } else return true;
                        },
                      })}
                      InputLabelProps={{ shrink: !!watch(item.name + "2") }}
                    />
                  </>
                );
              } else if (item.type == "html") {
                return (
                  <Box sx={{ width: "100%" }} mb={4} key={item.name}>
                    <Typography color="secondary" mb={1} sx={{ width: "100%" }}>
                      {item.label}
                    </Typography>
                    <EditorComponent
                      key={editorKey}
                      data={
                        record &&
                        record[item.name] &&
                        record[item.name][0] == "{"
                          ? JSON.parse(record[item.name])
                          : INITIAL_DATA
                      }
                      onChange={setData}
                      editorblock="editorjs-container"
                    />
                  </Box>
                );
              }
            })}
            <LoadingButton
              color="secondary"
              variant="contained"
              sx={{ mt: 4 }}
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              {id == "-1" ? "Utwórz" : "Zapisz"}
            </LoadingButton>
            <LoadingButton
              color="dark"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              disableElevation
              onClick={() => navigate(-1)}
              loading={isLoading}
            >
              Wstecz
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Grid>
  );
}
