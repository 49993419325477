import * as React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Error(props) {
  console.error(props.error);
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", pt: 4 }}
    >
      <Grid sx={{ textAlign: "center" }} item xs={10} sm={6} md={6} xl={2}>
        <Helmet>
          ‍<title>{"Błąd w reklamacji"}</title>
        </Helmet>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: "2rem", fontWeight: 700 }}
        >
          Błąd w reklamacji :D
        </Typography>
        <Typography
          sx={{ whiteSpace: "pre-line" }}
          variant="body1"
          gutterBottom
        >
          Aplikacja napotkała błąd i nie poradziła sobie z nim.
          <br />
          Spróbuj jeszcze raz lub{" "}
          <Link color="secondary" href="/kontakt">
            skontaktuj się z nami
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  );
}
