import * as React from "react";
import { Grid, Button, Box } from "@mui/material";
import DataTable from "../../features/adminList/DataTable";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AdminList() {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", py: 4 }}
    >
      <Grid item px={7} md={12} xs={12} xl={7}>
        <Button
          key={"backButton"}
          onClick={() => {
            navigate(-1);
          }}
          color={"dark"}
          variant="text"
        >
          {"<-"} Wróć na poprzednią stronę
        </Button>
      </Grid>
      <DataTable table={searchParams.get("table")} />
    </Grid>
  );
}
