import React, { memo, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS, i18n } from "./tools";
import { Grid } from "@mui/material";
import "./index.css";

const Editor = ({ data, onChange, editorblock }) => {
  const ref = useRef();
  //Initialize editorjs
  useEffect(() => {
    //Initialize editorjs if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: editorblock,
        tools: EDITOR_JS_TOOLS,
        i18n: i18n,
        readOnly: false,
        data: data,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data);
        },
      });
      ref.current = editor;

    }
    //Add a return function to handle cleanup
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);

  return (
    <Grid sx={{ width: "100%", border: "1px solid", borderColor: "secondary" }}>
      <div id={editorblock} style={{ width: "100%" }}></div>
    </Grid>
  );
};

export default memo(Editor);
