import * as React from "react";
import {Typography, Box, LinearProgress} from "@mui/material";

export default function QuestionProgress(props) {
  return (
    <Box sx={{display: props.display}}>
       <Typography variant="h3" color="secondary" sx={{mb:3, fontWeight: 700, fontSize: "2.8rem"}}>Formularz porady konsumenckiej</Typography>
       <Typography variant="body1" color="text.primary" sx={{fontSize: "0.75rem", textAlign: "center"}}>Postęp formularza:</Typography>
       <LinearProgress aria-label="Postęp formularza" color="secondary" variant="determinate" value={props.value} sx={{height: 8, mt: 3, mb: 10}} />
    </Box>
  );
}
