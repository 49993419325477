import * as React from "react";

import { useGetListQuery } from "../api/defaultApi";
import Error from "../../components/Error";
import { useCustom } from "@table-library/react-table-library/table";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/material-ui";
import { useRowSelect } from "@table-library/react-table-library/select";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import {
  TextField,
  Checkbox,
  Modal,
  Button,
  Box,
  Typography,
  FormGroup,
  TablePagination,
  CircularProgress,
  Paper,
  Grid,
} from "@mui/material";
import FaChevronUp from "@mui/icons-material/ExpandLess";
import FaChevronDown from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useSaveRecordMutation } from "../api/defaultApi";

export default function DataTable(props) {
  let navigate = useNavigate();
  const {
    data: nodes = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetListQuery(props.table);

  const [saveRecord, saveMutation = { isLoading, isError, error, isSuccess }] =
    useSaveRecordMutation();

  const [data, setData] = React.useState({ nodes });

  React.useEffect(() => {
    if (isSuccess) {
      setData({ nodes });
    }
  }, [nodes]);

  const handleDisactivate = () => {
    if (select.state.ids.length) {
      select.state.ids.map((id) => {
        saveRecord({ tableName: props.table, id, data: { active: false } });
      });
      setModalOpened(false);
    }
  };

  //* Resize *//

  const resize = { resizerHighlight: "#dee2e6" };

  //* Pagination *//

  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 15,
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {
    //console.log(action, state);
  }

  //* Search *//

  const [search, setSearch] = React.useState("");

  useCustom("search", data, {
    state: { search },
    onChange: onSearchChange,
  });

  function onSearchChange(action, state) {
    pagination.fns.onSetPage(0);
  }

  //* Filter *//

  const [isHide, setHide] = React.useState(false);

  useCustom("filter", data, {
    state: { isHide },
    onChange: onFilterChange,
  });

  function onFilterChange(action, state) {
    //console.log(action, state);
    pagination.fns.onSetPage(0);
  }

  //* Select *//

  const select = useRowSelect(data, {
    // onChange: onSelectChange,
  });

  // function onSelectChange(action, state) {
  //   if (action.type === "ADD_BY_ID_EXCLUSIVELY") {
  //     navigate("/admin/form?table=" + props.table + "&id=" + action.payload.id);
  //   }
  // }

  //* Sort *//

  const sort = useSort(
    data,
    {
      // onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <FaChevronUp />,
        iconDown: <FaChevronDown />,
      },
      sortFns: {
        label: (array) =>
          array.sort((a, b) => (a.label ?? "").localeCompare(b.label ?? "")),
        name: (array) =>
          array.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")),
        category: (array) =>
          array.sort((a, b) =>
            (a.category ?? "").localeCompare(b.category ?? "")
          ),
        order_number: (array) =>
          array.sort((a, b) => a.order_number - b.order_number),
        active: (array) => array.sort((a, b) => a.active - b.active),
        question_id: (array) =>
          array.sort((a, b) =>
            (a.question_id ?? "").localeCompare(b.question_id ?? "")
          ),
        next_question_id: (array) =>
          array.sort((a, b) =>
            (a.next_question_id ?? "").localeCompare(b.next_question_id ?? "")
          ),
        answer_id: (array) =>
          array.sort((a, b) =>
            (a.answer_id ?? "").localeCompare(b.answer_id ?? "")
          ),
        form_id: (array) =>
          array.sort((a, b) =>
            (a.form_id ?? "").localeCompare(b.form_id ?? "")
          ),
        created_by: (array) =>
          array.sort((a, b) =>
            (a.created_by ?? "").localeCompare(b.created_by ?? "")
          ),
        created_on: (array) =>
          array.sort((a, b) => new Date(a.created_on) - new Date(b.created_on)),

        updated_by: (array) =>
          array.sort((a, b) =>
            (a.updated_by ?? "").localeCompare(b.updated_by ?? "")
          ),
        updated_on: (array) =>
          array.sort((a, b) => new Date(a.updated_on) - new Date(b.updated_on)),
        last_login: (array) =>
          array.sort((a, b) => new Date(a.last_login) - new Date(b.last_login)),
      },
    }
  );

  // function onSortChange(action, state) {
  //   console.log(action, state);
  // }

  //* Edit *//

  // const handleEdit = (event) => {};

  //* Modal *//

  const [modalOpened, setModalOpened] = React.useState(false);

  //* Custom Modifiers *//

  let modifiedNodes = data.nodes;

  // search
  modifiedNodes = modifiedNodes.filter(
    (node) =>
      node.id.toLowerCase().includes(search.toLowerCase()) ||
      node.name?.toLowerCase().includes(search.toLowerCase()) ||
      node.label?.toLowerCase().includes(search.toLowerCase()) ||
      node.question_id?.toLowerCase().includes(search.toLowerCase()) ||
      node.next_question_id?.toLowerCase().includes(search.toLowerCase()) ||
      node.answer_id?.toLowerCase().includes(search.toLowerCase()) ||
      node.text?.toLowerCase().includes(search.toLowerCase()) ||
      node.category?.toLowerCase().includes(search.toLowerCase()) ||
      node.login?.toLowerCase().includes(search.toLowerCase()) ||
      node.help_text?.toLowerCase().includes(search.toLowerCase()) ||
      node.created_by?.toLowerCase().includes(search.toLowerCase()) ||
      node.updated_by?.toLowerCase().includes(search.toLowerCase()) ||
      node.form_id?.toLowerCase().includes(search.toLowerCase())
  );

  // filter
  modifiedNodes = isHide
    ? modifiedNodes.filter((node) => !node.isComplete)
    : modifiedNodes;

  // table labels

  const TABLE_LABELS = {
    question: "Pytania",
    question_option: "Odpowiedzi na pytania",
    answer: "Porady",
    user: "Użytkownicy",
    sys_dictionary: "Słownik Systemowy",
    module: "Moduły",
    form: "Formularz Reklamacyjny",
  };

  //* Columns *//

  const COLUMNS = [
    {
      label: "",
      renderCell: (item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{item.nodes?.length}</span>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              navigate("/admin/form?table=" + props.table + "&id=" + item.id)
            }
          >
            Edytuj
          </Button>
        </div>
      ),
      resize,
      sort: { sortKey: "TASKS" },
    },
    {
      label: "ID",
      renderCell: (item) => item.id,
      select: {
        renderHeaderCellSelect: () => (
          <Checkbox
            size="small"
            checked={select.state.all}
            color="secondary"
            sx={{
              color: "text.dark",
            }}
            indeterminate={!select.state.all && !select.state.none}
            onChange={select.fns.onToggleAll}
          />
        ),
        renderCellSelect: (item) => (
          <Checkbox
            size="small"
            color="secondary"
            sx={{
              color: "text.dark",
            }}
            checked={select.state.ids.includes(item.id)}
            onChange={() => select.fns.onToggleById(item.id)}
          />
        ),
      },
    },
    {
      label: "Aktywny",
      renderCell: (item) => (
        <Checkbox
          color="secondary"
          checked={item.active}
          disabled
          sx={{
            color: "text.dark",
            "& .MuiSvgIcon-root": { fontSize: 30 },
          }}
        />
      ),
      hide:
        props.table != "question_option" &&
        props.table != "module" &&
        props.table != "user",
      sort: { sortKey: "active" },
    },
    {
      label: "Login",
      renderCell: (item) => item.login,
      hide: props.table != "user",
      sort: { sortKey: "login" },
    },
    {
      label: "Rola",
      renderCell: (item) => item.role,
      hide: props.table != "user",
      sort: { sortKey: "role" },
    },
    {
      label: "Nazwa",
      renderCell: (item) => item.name,
      resize,
      hide: props.table == "question_option" || props.table == "user",
      sort: { sortKey: "name" },
    },
    {
      label: "Etykieta",
      renderCell: (item) => item.label,
      resize,
      hide:
        props.table == "module" ||
        props.table == "sys_dictionary" ||
        props.table == "user",
      sort: { sortKey: "label" },
    },
    {
      label: "Kategoria",
      renderCell: (item) => item.category,
      resize,
      hide: props.table != "question" && props.table != "answer",
      sort: { sortKey: "category" },
    },
    {
      label: "Kolejność",
      renderCell: (item) => item.order_number,
      hide:
        props.table == "answer" ||
        props.table == "module" ||
        props.table == "sys_dictionary" ||
        props.table == "form" ||
        props.table == "user",
      sort: { sortKey: "order_number" },
    },
    {
      label: "ID Pytania",
      renderCell: (item) => item.question_id,
      resize,
      hide: props.table != "question_option",
      sort: { sortKey: "question_id" },
    },
    {
      label: "ID Formularza Reklamacyjnego",
      renderCell: (item) => item.form_id,
      resize,
      hide: props.table != "answer",
      sort: { sortKey: "form_id" },
    },
    {
      label: "Odpowiedz - ID Pytania",
      renderCell: (item) => item.next_question_id,
      resize,
      hide: props.table != "question_option",
      sort: { sortKey: "next_question_id" },
    },
    {
      label: "Odpowiedz - ID Porady",
      renderCell: (item) => item.answer_id,
      resize,
      hide: props.table != "question_option",
      sort: { sortKey: "answer_id" },
    },
    {
      label: "Czas Ostatniego Logowania",
      renderCell: (item) => item.last_login,
      hide: props.table != "user",
      sort: { sortKey: "last_login" },
    },
    {
      label: "Ostatnia Modyfikacja",
      renderCell: (item) => item.updated_on,
      sort: { sortKey: "updated_on" },
    },
    {
      label: "Ostatnia Modyfikacja przez",
      renderCell: (item) => item.updated_by,
      sort: { sortKey: "updated_by" },
    },
    {
      label: "Utworzony",
      renderCell: (item) => item.created_on,
      sort: { sortKey: "created_on" },
    },
    {
      label: "Utworzony przez",
      renderCell: (item) => item.created_by,
      sort: { sortKey: "created_by" },
    },
  ];

  //* Theme *//
  const materialTheme = getTheme({
    ...DEFAULT_OPTIONS,
    striped: true,
    highlightOnHover: true,
  });
  const customTheme = {
    Table: `
        --data-table-library_grid-template-columns:   minmax(80px, max-content) repeat(auto-fill, 160px) 20%;
        td div {
          overflow: visible;
          white-space: break-spaces;
        }
      `,
  };

  const theme = useTheme([materialTheme, customTheme]);

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <>
      {/* Confirmation Form Modal*/}
      <Modal open={modalOpened} onClose={() => setModalOpened(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            backgroundColor: "#ffffff",
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          <Typography variant="h6" component="h2">
            Czy na pewno chcesz dezaktywować {select.state.ids.length} rekordów
            z tabeli {TABLE_LABELS[props.table]}?
          </Typography>
          <FormGroup>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              onClick={() => setModalOpened(false)}
            >
              Anuluj
            </Button>
            <Button
              color="alert"
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              onClick={handleDisactivate}
            >
              Dezaktywuj
            </Button>
          </FormGroup>
        </Box>
      </Modal>

      {/* Header Form */}
      <Grid item xs={11} sm={11} md={11} xl={7} sx={{ bgcolor: "#fff" }}>
        <Paper sx={{ p: 3 }} elevation={0}>
          <Typography mb={3} variant="h6">
            {TABLE_LABELS[props.table]}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                label="Wyszukiwarka"
                color="secondary"
                variant="outlined"
                size="small"
                sx={{ mr: 2 }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </Grid>
            <Grid item>
              {props.table != "module" && props.table != "sys_dictionary" ? (
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() =>
                    navigate("/admin/form?table=" + props.table + "&id=-1")
                  }
                >
                  Nowy
                </Button>
              ) : null}
              {props.table == "question_option" ? (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  disabled={!select.state.ids.length}
                  onClick={() => setModalOpened(true)}
                >
                  Dezaktywuj
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Paper>

        {/* Table */}

        <CompactTable
          columns={COLUMNS}
          data={{ ...data, nodes: modifiedNodes }}
          theme={theme}
          layout={{ custom: true, horizontalScroll: true }}
          select={select}
          sort={sort}
          pagination={pagination}
        />

        {/* Pagination */}
        <Paper sx={{ p: 3, width: "100%" }} elevation={0}>
          <TablePagination
            count={modifiedNodes.length}
            page={pagination.state.page}
            rowsPerPage={pagination.state.size}
            rowsPerPageOptions={[15, 50, 100]}
            labelRowsPerPage={"Wiersze na stronę"}
            onRowsPerPageChange={(event) =>
              pagination.fns.onSetSize(parseInt(event.target.value, 10))
            }
            onPageChange={(event, page) => pagination.fns.onSetPage(page)}
          />
        </Paper>
      </Grid>
    </>
  );
}
