import React from "react";
import {
  FormGroup,
  FormControl,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from "@mui/material";
import { CookieConsent } from "react-cookie-consent";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function CookieConsentCustom() {
  const location = useLocation();
  const [cookies, setCookie] = useCookies();
  const [state, setState] = React.useState({
    privacy: false,
    statute: false,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { privacy, statute } = state;
  const error = [privacy, statute].filter((v) => v).length !== 2;

  const buttonStyle = {
    background: "#487D8E",
    color: "#FFFFFF",
    padding: "16px",
  };
  const disabledButtonStyle = {
    ...buttonStyle,
    background: "#c6c6c6",
  };

  return (
    <CookieConsent
      visible={
        location.pathname === "/regulamin" ||
        location.pathname === "/polityka_prywatnosci"
          ? "hidden"
          : !cookies.ck_policy_agreement || !cookies.ck_statute_agreement
          ? "show"
          : undefined
      }
      customButtonProps={{ disabled: error ? true : false }}
      onAccept={() => {
        if (!error) {
          setCookie("ck_policy_agreement", privacy, {maxAge: 365 * 24 * 60 * 60});
          setCookie("ck_statute_agreement", statute, {maxAge: 365 * 24 * 60 * 60});
        }
      }}
      location="bottom"
      buttonText="Zapoznałem się"
      buttonStyle={!error ? buttonStyle : disabledButtonStyle}
      enableDeclineButton
      declineButtonText="Nie zgadzam się"
      declineButtonStyle={{
        color: "#FFFFFF",
        padding: "16px",
      }}
      onDecline={() => {
        handleClickOpen();
      }}
      overlay
      overlayStyle={{
        background: "rgba(0, 0, 0, 0.75)",
      }}
      style={{
        background: "#FDF3E6",
        color: "#242424",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "8px",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8} xl={6}>
          Cześć!
          <br />
          Znalazłeś(aś) się na portalu centrumkonsumenckie.pl. Zajmujemy się tu
          sprawami związanymi z problemami konsumentek i konsumentów oraz prawem
          konsumenckim. Zależy nam, aby z pomocą tego portalu uczynić rynek
          bezpieczniejszym i przyjaznym dla wszystkich.
          <br />
          <br />
          Portal centrumkonsumeckie.pl stworzyliśmy jako zestaw narzędzi, które
          mają ułatwić życie Tobie jako konsumentce(owi). W naszym serwisie
          możesz w łatwy sposób znaleźć odpowiedzi na swoje pytania lub
          rozwiązania dla swoich problemów.
          <br />
          <br />
          Obecnie nasz portal jest we wczesnej fazie rozwoju, więc jeśli
          napotkasz na pewne niedogodności lub usterki poinformuj nas o tym!
          Będzie to dla nas cenna informacja i bezcenna pomoc w dalszym rozwoju.
          <br />
          <br />
          Jeśli chcesz skorzystać z naszych usług zapoznaj się z polityką
          prywatności i regulaminem!
          <br />
          <Box sx={{ display: "flex" }}>
            <FormControl required error={error} component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={statute}
                      onChange={handleChange}
                      name="statute"
                      sx={{
                        color: "text.dark",
                        '& .MuiSvgIcon-root': { fontSize: 30 }
                      }}
                    />
                  }
                  label={
                    <Box>
                      Zapoznałem/am się i akceptuję{" "}
                      <Link
                        color={"secondary"}
                        variant="text"
                        href="/regulamin"
                        target="_blank"
                        sx={{
                          cursor: "pointer",
                          minWidth: 170,
                          color: "#242424",
                        }}
                      >
                        <b>Regulamin</b>
                      </Link>
                    </Box>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={privacy}
                      onChange={handleChange}
                      name="privacy"
                      sx={{
                        color: "text.dark",
                        '& .MuiSvgIcon-root': { fontSize: 30 }
                      }}
                    />
                  }
                  label={
                    <Box>
                      Zapoznałem/am się i akceptuję{" "}
                      <Link
                        color={"secondary"}
                        variant="text"
                        href="/polityka_prywatnosci"
                        target="_blank"
                        sx={{
                          cursor: "pointer",
                          minWidth: 170,
                          color: "#242424",
                        }}
                      >
                        <b>Politykę Prywatności</b>
                      </Link>
                    </Box>
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            background: "#FDF3E6",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" color="text.dark">
              Niestety nie zaakceptowałeś(aś) naszego regulaminu i polityki
              prywatności, dlatego nie możesz skorzystać z naszych usług. Mamy nadzieję, że w przyszłości zmienisz zdanie i będziemy mogli pomóc
              w rozwiązaniu Twoich problemów konsumenckich lub we wzbogaceniu
              Twojej wiedzy konsumenckiej.
              <br />
              <br />
              Czekamy na Ciebie.
              <br />
              Do zobaczenia!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            background: "#FDF3E6",
          }}
        >
          <Button color={"secondary"} variant="contained" onClick={handleClose}>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </CookieConsent>
  );
}
