import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setDictionary } from "../../components/dictionarySlice";

export const defaultApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_URL }),
  tagTypes: ["Records"],
  endpoints: (builder) => ({
    getQuestion: builder.query({
      query: (questionId) =>
        questionId ? `question/${questionId}` : "/question/first",
    }),
    getAnswer: builder.query({
      query: (answerId) => `/answer/${answerId}`,
    }),
    getForm: builder.query({
      query: (formId) => `/form/${formId}`,
    }),
    getList: builder.query({
      query(tableName) {
        return {
          url: `${tableName}`,
          credentials: "include",
        };
      },
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: "Records", id }))
          : ["Records"],
    }),
    getRecord: builder.query({
      query({ tableName, id }) {
        return {
          url: `${tableName}/${id}`,
          credentials: "include",
        };
      },
      providesTags: (result, error, id) => [{ type: 'Records', id }],
    }),
    saveRecord: builder.mutation({
      query({ tableName, id, data }) {
        if (!id || id == "-1") {
          return {
            url: `${tableName}`,
            method: "POST",
            body: data,
            credentials: "include",
          };
        }
        return {
          url: `${tableName}/${id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
      invalidatesTags: ["Records"],
    }),
    getDictionary: builder.query({
      query() {
        return {
          url: "sys_dictionary",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDictionary(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetQuestionQuery,
  useGetAnswerQuery,
  useGetFormQuery,
  useGetListQuery,
  useGetRecordQuery,
  useSaveRecordMutation,
} = defaultApi;
