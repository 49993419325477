import * as React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import edjsHTML from "editorjs-html";

export default function Contact() {
  const edjsParser = edjsHTML();

  const dictionary = useSelector((state) => state.dictionary.dictionary);
  const email = dictionary.find(
    (element) => element.id == "b9b15359-cc2b-11ee-9cae-0242ac120003"
  );
  const adres = dictionary.find(
    (element) => element.id == "d9d68ce3-cc2b-11ee-9cae-0242ac120003"
  );
  const phone = dictionary.find(
    (element) => element.id == "f194b2a2-cc2b-11ee-9cae-0242ac120003"
  );

  let html = [];
  if (email) {
    html = edjsParser.parse(JSON.parse(email.text));
  }
  let html2 = [];
  if (adres) {
    html2 = edjsParser.parse(JSON.parse(adres.text));
  }
  let html3 = [];
  if (phone) {
    html3 = edjsParser.parse(JSON.parse(phone.text));
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", py: 4 }}
    >
      <Helmet>
        <title>{"Kontakt - Centrum Konsumenckie"}</title>
        <meta name="description" content="Kontakt - Centrum Konsumenckie" />
      </Helmet>
      <Grid item xs={11} md={7} pt={1} sx={{ mt: 2 }}>
        <Typography
          variant="h5"
          color="text.primary"
          paragraph={true}
          sx={{ textAlign: "center", fontWeight: 700 }}
        >
          Kontakt
        </Typography>

        <Typography variant="body1" color="text.primary">
          Adres e-mail:{" "}
          {html.map((item) => parse(item))}
          <br />
          Adres do korespondencji:
          <br />
          {html2.map((item) => parse(item))}
          <br />
          Tel.{" "}
          {html3.map((item) => parse(item))}
          </Typography>
      </Grid>
    </Grid>
  );
}
