// tools.js
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import NestedList from "@editorjs/nested-list";
import Paragraph from "@editorjs/paragraph";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import LegalBox from "./LegalBox";
import LinkBox from "./LinkBox";
import HelpBox from "./HelpBox";
import AlertBox from "./AlertBox";
import Input from "./Input";
import Variable from "./Variable";
import MultilineVariable from "./MultilineVariable";
import Hyperlink from "editorjs-hyperlink";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    tunes: ["textAlign"],
  },
  legalBox: {
    class: LegalBox,
  },
  linkBox: {
    class: LinkBox,
  },
  alertBox: {
    class: AlertBox,
  },
  helpBox: {
    class: HelpBox,
  },
  Input: {
    class: Input,
  },
  Variable: {
    class: Variable,
  },
  MultilineVariable: {
    class: MultilineVariable,
  },
  embed: Embed,
  table: Table,
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  link: {
    class: Hyperlink,
    config: {
      shortcut: "CMD+L",
      target: "_blank",
      rel: "nofollow",
      availableTargets: ["_blank", "_self"],
      availableRels: ["author", "noreferrer"],
      validate: false,
    },
  },
  image: Image,
  header: {
    class: Header,
    tunes: ["textAlign"],
  },
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  simpleImage: SimpleImage,
  textAlign: {
    class: AlignmentTuneTool,
    config: {
      default: "left",
      blocks: {
        header: "center",
        list: "right",
      },
    },
  },
};

export const i18n = {
  toolNames: {
    Hyperlink: "Link",
  },
  tools: {
    hyperlink: {
      Save: "Zapisz",
      "Select target": "Target",
      "Select rel": "Rel",
    },
  },
};
