import * as React from "react";
import Grid from "@mui/material/Grid";
import LoginForm from "../../features/user/LoginForm";

export default function Login() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh"}}
    >
      <LoginForm/>
    </Grid>
  );
}
