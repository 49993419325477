import * as React from "react";
import Typography from "@mui/material/Typography";
import EditorRead from "../../components/editor/EditorRead";

export default function QuestionText(props) {
  return (
    <React.Fragment>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: "2rem", fontWeight: 700 }}
      >
        {props.title || ""}
      </Typography>
      <Typography sx={{ whiteSpace: "pre-line" }} variant="body1" gutterBottom>
        {props.text && props.text[0] == "{" ? (
          <EditorRead
            data={JSON.parse(props.text)}
            editorblock={"editorjsRead-container"}
          />
        ) : (
          ""
        )}
      </Typography>
    </React.Fragment>
  );
}
