import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputSelect = ({ name, control, label, choices }) => {
  const generateSingleOptions = () => {
    return choices.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <FormControl
      color="secondary"
      variant="standard"
      fullWidth
      sx={{
        mb: 4,
        borderBottom: "2px solid",
        borderColor: "secondary.main",
      }}
    >
      <InputLabel
        id="select-label"
        sx={{ color: "secondary.main" }}
      >
        {label}
      </InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select onChange={onChange} value={value || ""}>
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
