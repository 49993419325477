import React from "react";
import { Grid, Box, TextField, Alert, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useLoginUserMutation } from "../api/authApi";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  let navigate = useNavigate();
  const ADMIN_URL = "/admin";
  const [alertBox, setAlert] = React.useState({ open: false, text: "", severity: "success" });

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm();
  const methods = useForm({
    login: "",
    password: "",
  });

  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log("You successfully logged in");
      navigate(ADMIN_URL);
    }
    if (isError) {
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) => alert(el.message));
      } else {
        setAlert({ open: true, text: error.data.message, severity: "error"});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    loginUser(values);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, text: "", severity: "success"});
  };

  return (
    <Grid item xs={11} md={3} pt={1} sx={{ mt: 2 }}>
      <Snackbar
        open={alertBox.open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertBox.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertBox.text}
        </Alert>
      </Snackbar>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{}}
        >
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <TextField
              name="login"
              label="Login"
              type="login"
              color="secondary"
              variant="standard"
              required
              fullWidth
              error={!!errors["login"]}
              sx={{
                mb: 4,
              }}
              {...register("login", { required: "true" })}
            />
            <TextField
              name="password"
              label="Hasło"
              type="password"
              color="secondary"
              variant="standard"
              required
              fullWidth
              error={!!errors["password"]}
              sx={{
                mb: 4,
              }}
              {...register("password", { required: "true" })}
            />
            <LoadingButton
              color="secondary"
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Login
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Grid>
  );
}
