export default class Input {
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: "Input",
      icon: `<svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
        viewBox="0 0 27.963 27.963" xml:space="preserve">
   <g>
       <g id="c129_exclamation">
           <path d="M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984
               C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548
               c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z"/>
           <polygon points="15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158 		"/>
           <path d="M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528
               C16.441,19.575,15.468,18.546,13.998,18.546z"/>
       </g>
       <g id="Capa_1_207_">
       </g>
   </g>
   </svg>`,
    };
  }

  /**
   * Allow to press Enter inside the Warning
   *
   * @public
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return false;
  }

  /**
   * Warning Tool`s styles
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-alertBox",
      input: this.api.styles.input,
      message: "cdx-alertBox__message",
      title: "cdx-alertBox__title",
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {WarningData} data — previously saved data
   * @param {WarningConfig} config — user config for Tool
   * @param {object} api - Editor.js API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: "Input",
      message: data.message ? data.message : "Wpisz Tekst",
    };
  }

  /**
   * Create Warning Tool container with inputs
   *
   * @returns {Element}
   */
  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);
    const title = this._make("div", [this.CSS.title], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.message,
    });
    const message = this._make("input", [this.CSS.message], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.message,
    });
    container.appendChild(title);
    container.appendChild(message);

    return container;
  }

  /**
   * Extract Warning data from Warning Tool element
   *
   * @param {HTMLDivElement} alertElement - element to save
   * @returns {WarningData}
   */
  save(alertElement) {
    const message = alertElement.querySelector(`.${this.CSS.message}`);
    return Object.assign(this.data, {
      message: message.innerHTML,
    });
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Sanitizer config for Warning Tool saved data
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      message: {},
    };
  }
}
