import * as React from "react";
import { Grid, Typography, Link } from "@mui/material";
import aquila_log from "../public/logo_aquila.png";
import fk_logo from "../public/fk_logo.png";
import ec_logo from "../public/logo_euro_concret.png";
import stop_logo from "../public/sbblogo.jpg";
import rze_logo from "../public/rze_logo.png";
import forkon_logo from "../public/logo_forkon.png";
import { Helmet } from "react-helmet";

export default function Support() {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh", py: 4 }}
    >
       <Helmet>
        <title>{"Wspierają nas - Centrum Konsumenckie"}</title>
        <meta
          name="description"
          content="Wspierają nas - Centrum Konsumenckie"
        />
      </Helmet>
      <Grid
      item
        xs={11}
        md={6}
        xl={4}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container justifyContent="center">
          <Grid
            item
            xs={11}
            md={11}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              color="text.primary"
              paragraph={true}
              sx={{ textAlign: "center", fontWeight: 700 }}
            >
              Wspierają nas
            </Typography>
          </Grid>
          <Grid
            item
            xs={11}
            sm={4}
            md={4}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Link
              color="text.primary"
              target="_blank"
              rel="noopener"
              href="https://prawo-konsumenckie.pl/"
            >
              <img
                src={aquila_log}
                height="80px"
                alt="Stowarzyszenie Ochrony Konsumentów Aquila"
              />
            </Link>
            <Typography variant="body2">Stowarzyszenie Ochrony Konsumentów Aquila</Typography>
          </Grid>
          <Grid
            item
            xs={11}
            sm={4}
            md={4}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Link
              color="text.primary"
              target="_blank"
              rel="noopener"
              href="http://www.federacja-konsumentow.org.pl/"
            >
              <img src={fk_logo} height="60px" alt="Federacja Konsumentów" />
            </Link>
            <Typography variant="body2">Federacja Konsumentów</Typography>
          </Grid>
          <Grid
            item
            xs={11}
            sm={4}
            md={4}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Link
              color="text.primary"
              target="_blank"
              rel="noopener"
              href="http://euro-concret.pl/"
            >
              <img
                src={ec_logo}
                height="90px"
                alt="Stowarzyszenie Euro–Concret"
              />
            </Link>
            <Typography variant="body2">Stowarzyszenie Euro–Concret</Typography>
          </Grid>
          <Grid
            item
            xs={11}
            md={5}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Link
              color="text.primary"
              target="_blank"
              rel="noopener"
              href="https://www.bankowebezprawie.pl/"
            >
              <img
                src={stop_logo}
                height="90px"
                alt="Fundacja Stop Bankowemu Bezprawiu"
              />
            </Link>
            <Typography variant="body2">Fundacja Stop Bankowemu Bezprawiu</Typography>
          </Grid>
          <Grid
            item
            xs={11}
            md={5}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Link
              color="text.primary"
              target="_blank"
              rel="noopener"
              href="https://www.rzecznicy.konsumentow.eu/"
            >
              <img
                src={rze_logo}
                height="90px"
                alt="Stowarzyszenie Rzeczników Konsumentów"
              />
            </Link>
            <Typography variant="body2">Stowarzyszenie Rzeczników Konsumentów</Typography>
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            Wszystkie te organizacje są zrzeszone w{" "}
            <img
              src={forkon_logo}
              height="100px"
              alt="Forum Organizacji Konsumenckich FORKON"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
