import { configureStore } from '@reduxjs/toolkit'
import { defaultApi } from '../features/api/defaultApi'
import { authApi } from '../features/api/authApi'
import { userApi } from '../features/api/userApi'
import userReducer from '../features/user/userSlice';
import dictionaryReducer from '../components/dictionarySlice';

export default configureStore({
  reducer: {
    [defaultApi.reducerPath]: defaultApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    userState: userReducer,
    dictionary: dictionaryReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([defaultApi.middleware, authApi.middleware, userApi.middleware])
})