import * as React from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import Form from "../../features/adminForm/Form";
import { useGetRecordQuery } from "../../features/api/defaultApi";
import Error from "../../components/Error";

export default function AdminForm() {
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const tableName = searchParams.get("table");

  const {
    data: record,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRecordQuery({ tableName, id });

  if (isError || !id || !tableName) {
    return <Error error={error} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ bgcolor: "primary.main", minHeight: "80dvh" }}
    >
      <Helmet>
        <title>Form</title>
      </Helmet>
      {isLoading ? (
        <CircularProgress sx={{ my: 30 }} color="secondary" />
      ) : (
        <Form
          tableName={tableName}
          id={id}
          record={record}
        />
      )}
    </Grid>
  );
}
