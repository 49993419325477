import * as React from "react";
import Grid from "@mui/material/Grid";
import QuestionForm from "../features/question/QuestionForm";

export default function QuestionPage() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: "primary.main",  minHeight: "80dvh"}}
    >
      <QuestionForm/>
    </Grid>
  );
}
