import React from "react";
import { Typography, Box, Link, Grid } from "@mui/material";
import AnswerHelpBox from "./AnswerHelpBox";
import AnswerAlertBox from "./AnswerAlertBox";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import { LogoIcon } from "../../components/Navbar";
import Logo from "../../public/Logo.png";
import EditorRead from "../../components/editor/EditorRead";

const options = {
  replace({ name, attribs, children }) {
    if (!attribs) {
      return;
    }
    if (name === "answeralertbox") {
      const props = attributesToProps(attribs);
      return (
        <>
          <AnswerAlertBox {...props} />
          {domToReact(children, options)}
        </>
      );
    }
    if (name === "answerhelpbox") {
      const props = attributesToProps(attribs);
      return (
        <>
          <AnswerHelpBox {...props} />
          {domToReact(children, options)}
        </>
      );
    }
    if (name === "link") {
      const props = attributesToProps(attribs);
      return <Link {...props}>{props.value}</Link>;
    }
  },
};

export default function AnswerBody(props) {
  return (
    <div ref={props.refForBody}>
      <table>
        <Box
          sx={{
            "@media print": {
              "&": {
                backgroundImage: `url(${Logo})`,
                p: 3,
              },
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: "2rem",
              mb: 6,
              fontWeight: 700,
              "@media print": {
                "&": { overflow: "hidden", height: 0 },
              },
            }}
          >
            Oto twoja porada konsumencka:
          </Typography>
          <Box
            sx={{
              border: "1px solid #1B3649",
              pt: 4,
              p: 3,
              "@media print": {
                "&": { border: 0 },
              },
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                height: 0,
                "@media print": {
                  "&": {
                    overflow: "visible",
                    height: "auto",
                  },
                },
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                sx={{
                  pb: 2,
                  mb: 4,
                  borderBottom: "1px solid #1B3649",
                }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      mb: 2,
                    }}
                  >
                    Porada Konsumencka
                  </Typography>
                  <Typography
                    sx={{
                      width: "150px",
                      fontSize: "7px",
                    }}
                  >
                    Porada konsumencka wygenerowana automatycznie przez
                    formularz dostępny na stronie <u>centrumkonsumenckie.pl</u>
                  </Typography>
                </Grid>
                <Grid item>
                  <LogoIcon
                    viewBox="0 0 96 34"
                    sx={{
                      filter: "invert(100%)",
                      width: "180px",
                      height: "64px",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <thead>
              <tr>
                <Typography
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    height: 0,

                    "@media print": {
                      "&": {
                        overflow: "visible",
                        height: "auto",
                      },
                    },
                  }}
                ></Typography>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 700,
                      mb: 4,
                      textAlign: "center",
                    }}
                  >
                    {parse(props.answer.label)}
                  </Typography>
                  <Typography
                    sx={{ whiteSpace: "pre-line" }}
                    variant="body1"
                    gutterBottom
                  ></Typography>
                  {props.answer.text && props.answer.text[0] == "{" ? (
                    <EditorRead
                      data={JSON.parse(props.answer.text)}
                      editorblock="editorjsRead-container"
                    />
                  ) : <></>}
                </td>
              </tr>
            </tbody>
            <tfoot
              style={{
                overflow: "hidden",
                height: 0,
                "@media print": {
                  "&": {
                    pb: 4,
                    textAlign: "center",
                    height: "50px",
                    fontSize: "10px",
                    opacity: 0.8,
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    overflow: "visible",
                    height: "auto",
                    display: "block",
                  },
                },
              }}
            >
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    color="dark"
                    sx={{
                      mt: 4,
                      fontWeight: 400,
                      "@media print": {
                        "&": {
                          minHeight: "50px",
                        },
                      },
                    }}
                  >
                    Automatyczne odpowiedzi nie stanowią porad prawnych w
                    konkretnej sprawie Użytkownika, a jedynie informację o
                    obowiązujących przepisach. Odpowiedzi te mają charakter
                    wyłącznie informacyjno-pomocniczy.
                  </Typography>
                </td>
              </tr>
            </tfoot>
          </Box>
        </Box>
      </table>
    </div>
  );
}
