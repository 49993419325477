import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./app/App";
import * as serviceWorker from "./utils/serviceWorker";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.GA_ID || "G-39YWHLFWHD");

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);
serviceWorker.unregister();
