import * as React from "react";
import Grid from "@mui/material/Grid";
import AdvertiserForm from "../features/advertiser/AdvertiserForm";

export default function FormPage() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ bgcolor: "primary.main", py: 14, minHeight: "80dvh" }}
    >
      <AdvertiserForm/>
    </Grid>
  );
}
