import React, { Suspense } from "react";
import {
  Outlet,
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import QuestionPage from "../pages/QuestionPage";
import AnswerPage from "../pages/AnswerPage";
import FormPage from "../pages/FormPage";
import ErrorPage from "../pages/ErrorPage";
import Statute from "../pages/Statute";
import Contact from "../pages/Contact";
import Support from "../pages/Support";
import About from "../pages/About";
import Privacy from "../pages/Privacy";
import AdminLogin from "../pages/admin/AdminLogin";
import AdminList from "../pages/admin/AdminList";
import AdminMain from "../pages/admin/AdminMain";
import AdminForm from "../pages/admin/AdminForm";
import CookieConsentCustom from "../components/CookieConsentCustom";
import { userApi } from "../features/api/userApi";
import { defaultApi } from "../features/api/defaultApi";

const AdminLayout = React.lazy(() => import("./AdminLayout"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <QuestionPage />,
      },
      {
        path: "/pytanie/:id",
        element: <QuestionPage />,
        handle: { scrollMode: "pathname" },
      },
      {
        path: "/porada/:id",
        element: <AnswerPage />,
        handle: { scrollMode: "pathname" },
      },
      {
        path: "/reklamacja/:id",
        element: <FormPage />,
        handle: { scrollMode: "pathname" },
      },
      {
        path: "/regulamin",
        element: <Statute />,
      },
      {
        path: "/kontakt",
        element: <Contact />,
      },
      {
        path: "/wspieraja_nas",
        element: <Support />,
      },
      {
        path: "/nasz_zespol",
        element: <About />,
      },
      {
        path: "/polityka_prywatnosci",
        element: <Privacy />,
      },
      {
        path: "/login",
        element: <AdminLogin />,
      },
      {
        path: "/admin",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AdminLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <AdminMain />,
          },
          // {
          //   path: "/admin/flow",
          //   element: <AdminFlow />,
          // },
          {
            path: "/admin/list",
            element: <AdminList />,
          },
          {
            path: "/admin/form",
            element: <AdminForm />,
          },
        ],
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },

  // {
  //   path: "/logout",
  //   async action() {
  //     // We signout in a "resource route" that we can hit from a fetcher.Form
  //     //await fakeAuthProvider.signout();
  //     return redirect("/");
  //   },
  // },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

export function Layout() {
  const { data, isLoading, isError } =
    defaultApi.endpoints.getDictionary.useQuery();


  return (
    <React.Fragment>
      <CookieConsentCustom />
      <Navbar/>
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </React.Fragment>
  );
}
