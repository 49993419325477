import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAnswerQuery } from "../api/defaultApi";
import AnswerBody from "./AnswerBody";
import AnswerActionList from "./AnswerActionList";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import Error from "../../components/Error";

export default function AnswerForm() {
  // const [open, setOpen] = React.useState(false);
  // const [showAnswer, setShowAnswer] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const componentRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: answer, isLoading, isError, error } = useGetAnswerQuery(id);

  // handle print action, also with GA4
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: answer?.title,
    pageStyle:
      "@page { size: A4;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    onBeforePrint: () => {
      ReactGA.event({
        category: "Druk",
        action: "print_answer",
        label: "Wydrukuj poradę", // optional
      });
    },
  });

  // handle download action, also with GA4
  const handleDownload = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: answer?.title,
    pageStyle:
      "@page { size: A4;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    onBeforePrint: () => {
      ReactGA.event({
        category: "Pobranie",
        action: "download_answer",
        label: "Zapisz poradę do PDF", // optional
      });
    },
  });

  // for the next version
  // useEffect(() => {
  //   if (answer && answer.show_support) {
  //     const timer = setTimeout(() => {
  //       handleOpen();
  //     }, 20000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [answer]);
  // useEffect(() => {
  //   if (answer) {
  //     setShowAnswer(!answer.show_assessment);
  //   }
  //   if (answer && answer.show_support) {
  //     const onBeforeUnload = (e) => {
  //       handleOpen();
  //       e.preventDefault();
  //       e.returnValue = "";
  //     };
  //     window.addEventListener("beforeunload", onBeforeUnload);
  //     return () => {
  //       window.removeEventListener("beforeunload", onBeforeUnload);
  //     };
  //   }
  // }, [answer]);

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  if (isError || !answer) {
    return (
      <Grid sx={{ textAlign: "center" }} item xs={10} sm={6} md={6} xl={2}>
        <Error error={error} />
      </Grid>
    );
  }

  // if (!showAnswer && answer) {
  //   return (
  //     <Grid item xs={11} md={6} pt={1}>
  //       <Typography
  //         variant="h3"
  //         color="secondary"
  //         sx={{ mb: 4, fontWeight: 700, fontSize: "2.8rem" }}
  //       >
  //         Porada konsumenckia
  //       </Typography>
  //       <Typography
  //         variant="body2"
  //         color="dark"
  //         sx={{ mb: 4, fontWeight: 400 }}
  //       >
  //         W następnym kroku będziesz mógł(a) zapoznać się z poradą. Zanim jednak
  //         do tego przejdziemy prosimy o wypełnienie krótkiej ankiety, która
  //         pomoże nam lepiej wspierać konsumentki i konsumentów w ich problemach.
  //         Jeśli się spieszysz i pominiesz ten krok, to zrozumiemy, choć nasza
  //         wiedza o rynku będzie przez to uboższa. Pamiętaj, Twój głos jest
  //         ważny! Czy ankietę wypełnisz, czy też nie, dziękujemy za skorzystanie
  //         z naszych usług!
  //       </Typography>
  //       <Button
  //         key={"backButton"}
  //         color={"dark"}
  //         variant="outlined"
  //         onClick={() => setShowAnswer(!showAnswer)}
  //       >
  //         Przejdź do porady
  //       </Button>
  //     </Grid>
  //   );
  // }

  if (answer) {
    return (
      <Grid item xs={11} md={7}>
        <Helmet>
          <title>
            {"Porada - " + answer.label + " | Centrum Konsumenckie"}
          </title>
          <meta
            name="description"
            content={"Porada konsumencka - " + answer.label}
          />
        </Helmet>
        <AnswerBody refForBody={componentRef} answer={answer} />
        <AnswerActionList
          formID={answer.form_id || null}
          answerID={answer.id || null}
          printMethod={handlePrint}
          downloadMethod={handleDownload}
          goToContact={() => {
            ReactGA.event({
              category: "Link",
              action: "contact_us",
              label: "Skontaktuj się z nami", // optional
            });
            navigate("/kontakt");
          }}
        />
        <Box sx={{ textAlign: "center", mt: 6 }}>
          <Button
            key={"backButton"}
            onClick={() => navigate(-1)}
            color={"dark"}
            variant="outlined"
          >
            {"<-"} Wróć na poprzednią stronę
          </Button>
        </Box>

        {/* <Dialog
          open={open}
          onClose={handleClose}
          sx={{ bgcolor: "#FDF3E6" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "Cieszymy się, że skorzystałeś z naszego portalu i trzymamy kciuki, żeby porada okazała się pomocna!"
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="black" id="alert-dialog-description">
              Do powstania tej strony przyczyniła się wiedza wielu organizacji
              konsumenckich, więc jeśli zdecydujesz się wesprzeć finansowo jedną
              z nich (albo i wszystkie!) pozwoli im to jeszcze skuteczniej
              pomagać konsumentom.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              key={"backButton"}
              color={"dark"}
              variant="outlined"
              onClick={handleClose}
            >
              Przejdź do porady
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  }
}
