import React, { memo, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS, i18n } from "./tools";
import "./index.css";

const EditorRead = ({ data, editorblock, initialFunction }) => {
  const ref = useRef();

  const css = `.ce-block__content, .ce-toolbar__content { max-width:calc(100% - 20px) !important; } .cdx-block { max-width: 100% !important; }
    .codex-editor__redactor {
      padding-bottom: 0 !important;
    }
  `;

  //Initialize editorjs
  useEffect(() => {
    //Initialize editorjs if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: editorblock,
        tools: EDITOR_JS_TOOLS,
        readOnly: true,
        data: data,
        defaultBlock: "embed",
        i18n: i18n,
        onReady: () => {
          if (initialFunction) initialFunction();
        },
      });
      ref.current = editor;
    }
    //Add a return function to handle cleanup
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);

  return (
    <div
      id={editorblock}
      style={{
        width: "100%",
      }}
    >
      <style>{css}</style>
    </div>
  );
};

export default memo(EditorRead);
