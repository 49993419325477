import * as React from "react";
import Box from "@mui/material/Box";
import Option from "./Option";

export default function OptionList(props) {
  let renderedOptions = null;
  if (props.options.length) {
    const orderedQuestions = props.options
      .slice()
      .sort((a, b) => (a.order_number > b.order_number ? 1 : -1));
    renderedOptions = orderedQuestions.map((option) => {
      if (option.active) {
        return (
          <Option
            onSelect={props.onSelect}
            id={option.id}
            key={option.id}
            data={option}
            disabled={(!option.next_question_id && !option.answer_id)}
          />
        );
      }
      return null;
    });
  }
  return <Box sx={{ mt: 6 }}>{renderedOptions}</Box>;
}
