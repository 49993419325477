import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dictionary: [],
};

export const dictionarySlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    setDictionary: (state, action) => {
      state.dictionary = action.payload;
    },
  },
});

export default dictionarySlice.reducer;

export const { setDictionary } = dictionarySlice.actions;
