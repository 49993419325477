export default class Input {
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: "Variable",
      icon: `<svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
        viewBox="0 0 27.963 27.963" xml:space="preserve">
   <g>
       <g id="c129_exclamation">
           <path d="M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984
               C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548
               c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z"/>
           <polygon points="15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158 		"/>
           <path d="M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528
               C16.441,19.575,15.468,18.546,13.998,18.546z"/>
       </g>
       <g id="Capa_1_207_">
       </g>
   </g>
   </svg>`,
    };
  }

  /**
   * Allow to press Enter inside the Warning
   *
   * @public
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return false;
  }

  /**
   * Warning Tool`s styles
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-inputBox", //cdx-alertBox
      adminInput: this.api.styles.input,
      input: "cdx-inputBox__input",
      label: "cdx-inputBox__label",
      errorLabel: "cdx-inputBox__errorLabel",
      optionContainer: "cdx-inputBox__optionContainer",
      inputLabel: "cdx-inputBox__label_label",
      placeholder: "cdx-inputBox__label_placeholder",
      maxlength: "cdx-inputBox__label_maxlength",
      variableNumber: "cdx-inputBox__label_variableNumber",
      type: "cdx-inputBox__label_type",
      disabled: "cdx-inputBox__label_disabled",
      required: "cdx-inputBox__label_required",
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {WarningData} data — previously saved data
   * @param {WarningConfig} config — user config for Tool
   * @param {object} api - Editor.js API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: "Variable",
      label: data.label ? data.label : "Wpisz Nazwe Pola",
      value: data.value ? data.value : "",
      placeholder: data.placeholder
        ? data.placeholder
        : "Podaj tekst zastępczy",
      maxlength: data.maxlength ? data.maxlength : "100",
      variableNumber: data.variableNumber ? data.variableNumber : "",
      type: data.type ? data.type : "text",
      disabled: data.disabled ? data.disabled : "false",
      required: data.required ? data.required : "false",
    };
  }

  /**
   * Create Warning Tool container with inputs
   *
   * @returns {Element}
   */
  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);
    const errorMessage = this._make(
      "div",
      [this.CSS.label, this.CSS.inputLabel, this.CSS.errorLabel],
      {
        innerHTML: "Uzupełnij Pole",
      }
    );
    const label = this._make("label", [this.CSS.label, this.CSS.inputLabel], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.label,
    });
    const input = this._make("input", [this.CSS.input], {
      id: this.data.variableNumber,
      autocomplete: "off",
      value: this.data.value,
      placeholder: this.data.placeholder,
      maxLength: this.data.maxlength,
      type: this.data.type,
      readOnly: !this.readOnly,
      contentEditable: this.readOnly,
    });
    if (this.data.disabled == "true") {
      input.disabled = "disabled";
    }
    if (this.data.required == "true") {
      input.required = "required";
      label.classList.add("required");
      input.addEventListener("invalid", (e) => {
        errorMessage.classList.add("cdx-show");
        input.classList.add("cdx-inputBox__input-error");
      });
      input.addEventListener("change", (e) => {
        if (input.checkValidity()) {
          errorMessage.classList.remove("cdx-show");
          input.classList.remove("cdx-inputBox__input-error");
        }
      });
    }
    if (this.data.type == "tel") {
      input.pattern = "[0-9]{3}-[0-9]{3}-[0-9]{3}";
    }

    if (!this.readOnly) {
      container.style.border = "1px solid rgb(31, 39, 249)";
      container.style.padding = "5px";

      const optionContainer = this._make("div", [this.CSS.optionContainer], {});
      const label2 = this._make("div", [this.CSS.label], {
        innerHTML: "Tekst zastępczy",
      });
      const placeholder = this._make(
        "div",
        [this.CSS.adminInput, , this.CSS.placeholder],
        {
          contentEditable: true,
          innerHTML: this.data.placeholder,
        }
      );
      const label3 = this._make("div", [this.CSS.label], {
        innerHTML: "Maksymlana liczba znaków",
      });
      const maxlength = this._make(
        "div",
        [this.CSS.adminInput, this.CSS.maxlength],
        {
          contentEditable: true,
          innerHTML: this.data.maxlength,
        }
      );
      const label4 = this._make("div", [this.CSS.label], {
        innerHTML: "Wartość z Porady",
      });

      const variable = this._make(
        "select",
        [this.CSS.adminInput, this.CSS.variableNumber],
        {
          name: "variableNumber",
          id: "variableNumber",
        }
      );
      const option1 = this._make("option", [], {
        value: "",
        innerHTML: "",
        selected: this.data.variableNumber == "",
      });
      const option2 = this._make("option", [], {
        value: "1",
        innerHTML: "1",
        selected: this.data.variableNumber == "1",
      });
      const option3 = this._make("option", [], {
        value: "2",
        innerHTML: "2",
        selected: this.data.variableNumber == "2",
      });
      const option4 = this._make("option", [], {
        value: "3",
        innerHTML: "3",
        selected: this.data.variableNumber == "3",
      });
      variable.appendChild(option1);
      variable.appendChild(option2);
      variable.appendChild(option3);
      variable.appendChild(option4);

      const label5 = this._make("div", [this.CSS.label], {
        innerHTML: "Aktywne",
      });
      const disabled = this._make(
        "select",
        [this.CSS.adminInput, this.CSS.disabled],
        {
          name: "disabled",
          id: "disabled",
        }
      );
      const disabledOption1 = this._make("option", [], {
        value: "false",
        innerHTML: "Tak",
        selected: this.data.disabled == "false",
      });
      const disabledOption2 = this._make("option", [], {
        value: "true",
        innerHTML: "Nie",
        selected: this.data.disabled == "true",
      });

      disabled.appendChild(disabledOption1);
      disabled.appendChild(disabledOption2);

      const label6 = this._make("div", [this.CSS.label], {
        innerHTML: "Obowiązkowe",
      });
      const required = this._make(
        "select",
        [this.CSS.adminInput, this.CSS.required],
        {
          name: "required",
          id: "required",
        }
      );
      const requiredOption1 = this._make("option", [], {
        value: "false",
        innerHTML: "Nie",
        selected: this.data.required == "false",
      });
      const requiredOption2 = this._make("option", [], {
        value: "true",
        innerHTML: "Tak",
        selected: this.data.required == "true",
      });
      required.appendChild(requiredOption1);
      required.appendChild(requiredOption2);

      const label7 = this._make("div", [this.CSS.label], {
        innerHTML: "Typ",
      });
      const type = this._make("select", [this.CSS.adminInput, this.CSS.type], {
        name: "type",
        id: "type",
      });
      const typeOption1 = this._make("option", [], {
        value: "text",
        innerHTML: "Tekst",
        selected: this.data.type == "text" || this.data.type == "",
      });
      const typeOption2 = this._make("option", [], {
        value: "email",
        innerHTML: "Email",
        selected: this.data.type == "email",
      });
      const typeOption3 = this._make("option", [], {
        value: "date",
        innerHTML: "Data",
        selected: this.data.type == "date",
      });
      const typeOption4 = this._make("option", [], {
        value: "tel",
        innerHTML: "Telefon",
        selected: this.data.type == "tel",
      });
      type.appendChild(typeOption1);
      type.appendChild(typeOption2);
      type.appendChild(typeOption3);
      type.appendChild(typeOption4);

      optionContainer.appendChild(label2);
      optionContainer.appendChild(placeholder);
      optionContainer.appendChild(label3);
      optionContainer.appendChild(maxlength);
      optionContainer.appendChild(label4);
      optionContainer.appendChild(variable);
      optionContainer.appendChild(label5);
      optionContainer.appendChild(disabled);
      optionContainer.appendChild(label6);
      optionContainer.appendChild(required);
      optionContainer.appendChild(label7);
      optionContainer.appendChild(type);
      container.appendChild(optionContainer);
    }

    container.appendChild(label);
    container.appendChild(input);
    container.appendChild(errorMessage);

    return container;
  }

  /**
   * Extract Warning data from Warning Tool element
   *
   * @param {HTMLDivElement} alertElement - element to save
   * @returns {WarningData}
   */
  save(alertElement) {
    if (!this.readOnly) {
      const placeholder = alertElement.querySelector(
        `.${this.CSS.placeholder}`
      );
      const maxlength = alertElement.querySelector(`.${this.CSS.maxlength}`);
      const label = alertElement.querySelector(`.${this.CSS.inputLabel}`);
      const value = alertElement.querySelector(`.${this.CSS.input}`);
      const variableNumber = alertElement.querySelector(
        `.${this.CSS.variableNumber}`
      );
      const disabled = alertElement.querySelector(`.${this.CSS.disabled}`);
      const required = alertElement.querySelector(`.${this.CSS.required}`);
      const type = alertElement.querySelector(`.${this.CSS.type}`);
      return Object.assign(this.data, {
        placeholder: placeholder.innerHTML,
        maxlength: maxlength.innerHTML,
        label: label.innerHTML,
        value: value.innerHTML,
        variableNumber: variableNumber.value,
        disabled: disabled.value,
        required: required.value,
        type: type.value,
      });
    }
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Sanitizer config for Warning Tool saved data
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      message: {},
    };
  }
}
