import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
// import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Divider, useTheme, useMediaQuery } from "@mui/material";
import { LogoIcon } from "./Navbar";
import { useSelector } from "react-redux";
import edjsHTML from "editorjs-html";
import parse from "html-react-parser";

export default function Footer() {
  function customParser(block){
    return `<span key=${block.data.id}> ${block.data.text} </span>`;
  }
  const edjsParser = edjsHTML({paragraph: customParser});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  let LinkStyle = {
    display: "flex",
    mb: 2,
    textDecoration: "none",
  };

  const dictionary = useSelector((state) => state.dictionary.dictionary);
  const text1 = dictionary.find(
    (element) => element.id == "ac291f55-cb98-11ee-9bb1-0242ac120002"
  );
  const text2 = dictionary.find(
    (element) => element.id == "daf40ed5-cc22-11ee-9cae-0242ac120003"
  );
  let html = [];
  if (text1) {
    html = edjsParser.parse(JSON.parse(text1.text));
  }
  let html2 = [];
  if (text2) {
    html2 = edjsParser.parse(JSON.parse(text2.text));
  }

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.secondary.main
            : theme.palette.secondary.main,
        p: matches ? 3 : 10,
      }}
    >
      <Container maxWidth="lg">
        <Grid container columnSpacing={11}>
          <Grid item xs={12} md={5}>
            <LogoIcon
              viewBox="0 0 96 34"
              sx={{
                width: "180px",
                height: "64px",
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                pt: 3,
              }}
            >
              {html.map((item) => parse(item))}
            </Typography>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          <Grid item xs={12} md={2}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              Uzyskaj wsparcie
            </Typography>
            <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/"
            >
              Wypełnij formularz
            </Link>
            {/* <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/"
            >
              Baza wiedzy
            </Link> */}
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              Centrum Konsumenckie
            </Typography>
            <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/"
            >
              Strona główna
            </Link>
            {/* <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/"
            >
              Aktualności
            </Link> */}
            <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/wspieraja_nas"
            >
              Wspierają nas
            </Link>
            <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/nasz_zespol"
            >
              Nasz zespół
            </Link>
            <Link
              variant="body2"
              color="text.secondary"
              sx={LinkStyle}
              href="/kontakt"
            >
              Kontakt
            </Link>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              Obserwuj nas
            </Typography>
            {/* <Link href="https://www.facebook.com/" color="text.secondary">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/"
              color="text.secondary"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="text.secondary">
              <Twitter />
            </Link> */}
          </Grid>
        </Grid>
        <Box mt={8} mb={4}>
          <Divider sx={{ borderColor: "text.secondary" }} />
        </Box>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={5.6}>
            <Typography variant="body2" color="text.secondary">
              {html2.map((item) => parse(item))}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={1.6}>
            <Link
              variant="body2"
              color="text.secondary"
              href="/"
            >
              Impressum
            </Link>
          </Grid> */}
          <Grid item xs={12} md={1.6}>
            <Link variant="body2" color="text.secondary" href="/regulamin">
              Regulamin
            </Link>
          </Grid>
          <Grid item xs={12} md={1.6}>
            <Link
              variant="body2"
              color="text.secondary"
              href="/polityka_prywatnosci"
            >
              Polityka Prywatności
            </Link>
          </Grid>
          {/* <Grid item xs={12} md={1.6}>
            <Link
              variant="body2"
              color="text.secondary"
              href="/"
            >
              Ustawienia Cookies
            </Link>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}
