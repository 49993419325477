export default class HelpBox {
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: "Podpowiedź",
      icon: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"/></svg>`,
    };
  }

  /**
   * Allow to press Enter inside the Warning
   *
   * @public
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return false;
  }

  /**
   * Warning Tool`s styles
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-link",
      input: this.api.styles.input,
      title: "cdx-link__title",
      message: "cdx-link__message",
      child1: "cdx-link__child1",
      child2: "cdx-link__child2",
      child3: "cdx-link__child3",
      button: "cdx-link__button",
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {WarningData} data — previously saved data
   * @param {WarningConfig} config — user config for Tool
   * @param {object} api - Editor.js API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: "Rozwiń",
      message: data.message ? data.message : "Wpisz tekst",
      buttonShow: "Pokaż definicję",
    };
  }

  /**
   * Create Warning Tool container with inputs
   *
   * @returns {Element}
   */
  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);

    const child1 = this._make("div", [this.CSS.child1]);
    const title = this._make("div", [this.CSS.title], {
      contentEditable: false,
      innerHTML: this.data.title,
    });

    child1.appendChild(title);
    let r = Math.random().toString(36).slice(2, 7);

    const child2 = this._make("div", [this.CSS.child2]);
    const link = this._make("button", [this.CSS.button], {
      id: "button" + r,
      type: "button", 
      innerHTML: this.data.buttonShow,
      onclick: function() {
        var elChild3 = document.getElementById(r);
        var elButton = document.getElementById("button" + r);
        if(elChild3.style.display == "none") {
          elChild3.style.display = "block";
          elButton.innerHTML = "Ukryj definicję";
        } else {
          elChild3.style.display = "none";
          elButton.innerHTML = "Pokaż definicję";
        }
        
      }
    });

    child2.appendChild(link);
    const child3 = this._make("div", [this.CSS.child3], { id: r, style: "display: none" });
    const message = this._make("div", [this.CSS.message], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.message,
    });
    child3.appendChild(message);

    container.appendChild(child1);
    container.appendChild(child2);
    container.appendChild(child3);

    return container;
  }

  /**
   * Extract Warning data from Warning Tool element
   *
   * @param {HTMLDivElement} linkElement - element to save
   * @returns {WarningData}
   */
  save(linkElement) {
    const message = linkElement.querySelector(`.${this.CSS.message}`);
    return Object.assign(this.data, {
      message: message.innerHTML,
    });
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Sanitizer config for Warning Tool saved data
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      message: {},
    };
  }
}
